import css from './Footer.css'

const Footer = () => {
  return (
    <div>
      

        <ul className='socials'>
        <li>-Thank You for supporting me to be part programming community-</li>
        <li>To God, thank you for leading me to this opportunity.</li>
        <li>My ever supportive wife, Ann.</li>
        <li>To my mentors and classmates from GoIT Phillippines.</li>
        <li>To all the available public sources and community of WebDev</li>
        <li>Mr.Abdul Gofur for the amazing webiste background (vecteezy.com). </li>
        <li>Mr.Teguh Jati Prasetyo for the amazing webiste image (vecteezy.com).</li>
        </ul> 


     
    </div>
  )
}

export default Footer
